/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import 'ngx-toastr/toastr';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

body {
  width: 100vw;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
  background-color: white;
  overflow-x: hidden;
}

/*::-webkit-scrollbar {
  width: 0px;
  background: gray;
}*/

::-webkit-scrollbar-track {
  width: 1px;
  background: lightgray;
}

.content {
  padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
  overflow: hidden;
  display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  transform: none;
}

.navbar-blue {
  background-color: #4e89f1;
}

.card-light-blue {
  background-color: #d8e6ff;
}

.content-wrapper {
  background-color: white !important;
}

.text-gradient-blue {
  color: #4b80de;
}

.bg-gradient-blue-color {
  background-color: #4e89f1;
}

.elevation-4 {
  box-shadow: none !important;
}
.nav-pills .nav-link {
  color: black;
  background-color: #d8e6ff;
  padding: 10px;
}

.swiper-button-prev {
  left: 0 !important;
  border-radius: 0 50px 50px 0;
  color: blue;
  width: 56px !important;
}

.swiper-button-next {
  right: 0 !important;
  border-radius: 50px 0 0 50px;
  color: blue;
  width: 56px !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 20px;
}
.loader {
  position: absolute;
  top: calc(50%);
  left: calc(50%);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 5px solid #eef3f2;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 5px solid #ef6210;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 5px solid hsl(129, 86%, 48%);
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.file-size {
  font-size: 12px;
}

.brand-link {
  padding: 0px 3px !important;
}

@media only screen and (min-width: 1440px) {
  .direct-chat-messages {
    height: 300px;
  }
}

// Quill CSS
.quill-editor{
  height: 280px;
  overflow: scroll;
  position:relative;
}
.quill-editor::-webkit-scrollbar {
  display: none;
}
.quill-height{
  height: 400px;
  background-color: white !important;
}
.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.ql-editor {
  min-height: 250px;
}
